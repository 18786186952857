// src/App.js
import React, { useState, useContext } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom';
import { AuthProvider, AuthContext } from './AuthContext';
import { VenueProvider } from './contexts/VenueContext';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import ForgotPassword from './pages/ForgotPassword';
import VenueList from './pages/VenueList';
import PublicView from './pages/PublicView';
import VenueDetail from './pages/VenueDetail';
import AdminPanel from './pages/AdminPanel';
import Header from './components/Header';
import Footer from './components/Footer';
import ViewAllVenues from './pages/ViewAllVenues';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';
import MenuPortal from './pages/MenuPortal'; // Import MenuPortal component

import './App.css';

function App() {
  const [searchQuery, setSearchQuery] = useState('');
  const [priceFilter, setPriceFilter] = useState('All');

  return (
    <AuthProvider>
      <VenueProvider>
        <Router>
          <AppContent
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            priceFilter={priceFilter}
            setPriceFilter={setPriceFilter}
          />
        </Router>
      </VenueProvider>
    </AuthProvider>
  );
}

function AppContent({
  searchQuery,
  setSearchQuery,
  priceFilter,
  setPriceFilter,
}) {
  const location = useLocation();
  const hideHeaderFooter =
    location.pathname === '/privacy-policy' ||
    location.pathname === '/termsofuse' ||
    location.pathname === '/login' ||
    location.pathname === '/signup' ||
    location.pathname === '/forgot-password'; // Hide header and footer on these pages

  return (
    <div className="App">
      {!hideHeaderFooter && (
        <ConditionalHeader
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          priceFilter={priceFilter}
          setPriceFilter={setPriceFilter}
        />
      )}
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route
          path="/venues"
          element={<VenueProtectedRoute component={VenueList} />}
        />
        <Route
          path="/menu-portal"
          element={<VenueProtectedRoute component={MenuPortal} />} // Add this route
        />
        <Route
          path="/admin"
          element={<AdminProtectedRoute component={AdminPanel} />}
        />
        <Route
          path="/public"
          element={
            <PublicView searchQuery={searchQuery} priceFilter={priceFilter} />
          }
        />
        <Route path="/venue/:id" element={<VenueDetail />} />
        <Route
          path="/all-venues"
          element={
            <ViewAllVenues
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              priceFilter={priceFilter}
              setPriceFilter={setPriceFilter}
            />
          }
        />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/termsofuse" element={<TermsOfUse />} />
        <Route
          path="/"
          element={
            <PublicView searchQuery={searchQuery} priceFilter={priceFilter} />
          }
        />
      </Routes>
      {!hideHeaderFooter && <Footer />}
    </div>
  );
}

function ConditionalHeader({
  searchQuery,
  setSearchQuery,
  priceFilter,
  setPriceFilter,
}) {
  const location = useLocation();
  const isVenueDetailPage = location.pathname.startsWith('/venue/');
  const isLoginPage = location.pathname === '/login';
  const isSignUpPage = location.pathname === '/signup';
  const isForgotPasswordPage = location.pathname === '/forgot-password';
  const isVenuesPage = location.pathname === '/venues';
  const isMenuPortalPage = location.pathname === '/menu-portal';

  if (
    isVenueDetailPage ||
    isLoginPage ||
    isSignUpPage ||
    isForgotPasswordPage ||
    isVenuesPage ||
    isMenuPortalPage
  ) {
    return null;
  }

  return (
    <Header
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      priceFilter={priceFilter}
      setPriceFilter={setPriceFilter}
    />
  );
}

function VenueProtectedRoute({ component: Component }) {
  const { currentUser, foodieData } = useContext(AuthContext);
  if (!currentUser) {
    return <Navigate replace to="/login" />;
  }
  if (foodieData) {
    // If the user is a foodie, redirect to public view
    return <Navigate replace to="/public" />;
  }
  return <Component />;
}

function AdminProtectedRoute({ component: Component }) {
  const { currentUser } = useContext(AuthContext);
  if (!currentUser) {
    return <Navigate replace to="/login" />;
  }
  if (currentUser.email !== 'admin@spenu.com') {
    return <Navigate replace to="/venues" />;
  }
  return <Component />;
}

export default App;
