// src/components/Header.js
import React, { useState, useEffect, useContext } from 'react';
import { useLocation, Link } from 'react-router-dom';
import CityDropdown from './CityDropdown';
import Preferences from './Preferences'; // Import the Preferences component
import { fetchVenueItems } from '../services/firebaseService';
import { AuthContext } from '../AuthContext'; // Import AuthContext
import './Header.css';

const Header = ({ searchQuery, setSearchQuery, setPriceFilter }) => {
  const location = useLocation();
  const isVenueDetailPage = location.pathname.startsWith('/venue/');
  const [venueItems, setVenueItems] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');
  const { currentUser, foodieData } = useContext(AuthContext); // Get currentUser and foodieData

  useEffect(() => {
    const loadVenues = async () => {
      const venues = await fetchVenueItems();
      setVenueItems(venues);
    };
    loadVenues();
  }, []);

  const handleCitySelect = (city) => {
    setSelectedCity(city);
    setSearchQuery(city);
  };

  const clearSearch = () => {
    setSearchQuery('');
    setPriceFilter('All');
    setSelectedCity('');
  };

  return (
    <div>
      <div className="top-bar">
        <div className="logo-container">
          <a href="https://www.spenu.co.uk">
            <img src={`${process.env.PUBLIC_URL}/spenu.png`} alt="Spenu Logo" className="top-logo" />
          </a>
        </div>
        <div className="header-right">
          <CityDropdown venueItems={venueItems} onCitySelect={handleCitySelect} selectedCity={selectedCity} />
          <a href="/all-venues" className="view-all-link" target="_blank" rel="noopener noreferrer">
            View all
          </a>
          {currentUser ? (
            // Show initials button with dropdown
            <Preferences fullName={foodieData?.fullName} />
          ) : (
            // Show only Login button
            <Link to="/login" className="fixed-bar-login-button">
              Login
            </Link>
          )}
        </div>
      </div>
      {!isVenueDetailPage && (
        <header className="header">
          <div className="header-center">
            <h1 className="header-title">Discover the perfect dishes</h1>
            <div className="search-container">
              <input
                type="text"
                placeholder="Search multiple dishes (e.g., lobster steak tomato)"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="search-input"
                aria-label="Search multiple dishes"
              />
              {searchQuery && (
                <button className="clear-search" onClick={clearSearch} aria-label="Clear search">
                  <span className="clear-icon">×</span>
                </button>
              )}
            </div>
          </div>
        </header>
      )}
    </div>
  );
};

export default Header;
