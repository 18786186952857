// src/pages/VenueList.js
import React, { useContext, useState, useEffect, useRef } from 'react';
import { AuthContext } from '../AuthContext';
import Modal from '../components/Modal';
import UploadMenuModal from '../components/UploadMenuModal';
import {
  fetchVenueItems,
  updateVenuePromo,
  fetchMenuItemsByVenue,
  updateMenuSummary,
  updateMenuType,
  deleteMenuItem,
} from '../services/firebaseService';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import CustomAlert from '../components/CustomAlert';
import { Link } from 'react-router-dom';
import './VenueList.css'; // Import the CSS file

const VenueList = () => {
  const { currentUser } = useContext(AuthContext);
  const [venueItems, setVenueItems] = useState([]);
  const [menuItems, setMenuItems] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [selectedVenue, setSelectedVenue] = useState(null);
  const [venueUpdates, setVenueUpdates] = useState({});
  const [uploadVenueId, setUploadVenueId] = useState('');
  const [uploadVenueName, setUploadVenueName] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  // Create refs for each venue's menu list
  const menuListRefs = useRef({});

  useEffect(() => {
    if (currentUser) {
      loadVenueItems();
    }
  }, [currentUser]);

  const loadVenueItems = async () => {
    try {
      const items = await fetchVenueItems(currentUser.uid);
      setVenueItems(items);
      const menuItemsByVenue = {};
      for (const item of items) {
        const menus = await fetchMenuItemsByVenue(item.venueid);
        menuItemsByVenue[item.venueid] = await Promise.all(
          menus.map(async (menu) => {
            const publicUrl = await getPublicUrl(menu.imageURL);
            return { ...menu, publicUrl };
          })
        );
      }
      setMenuItems(menuItemsByVenue);
    } catch (error) {
      console.error('Error fetching venue items: ', error);
      setAlertMessage('Error fetching venue items.');
    }
  };

  const getPublicUrl = async (gsUrl) => {
    const storage = getStorage();
    const path = gsUrl.replace(`gs://${storage.app.options.storageBucket}/`, '');
    const storageRef = ref(storage, path);
    const publicUrl = await getDownloadURL(storageRef);
    return publicUrl;
  };

  const handleImageClick = (description) => {
    setSelectedVenue(description);
    setIsModalOpen(true);
  };

  const handleUpdateField = async (id, field, value) => {
    const updateData = { [field]: value };
    try {
      await updateVenuePromo(id, updateData);
      setAlertMessage(`${field} updated successfully!`);
      loadVenueItems();
    } catch (error) {
      console.error(`Error updating ${field}: `, error);
      setAlertMessage(`Failed to update ${field}.`);
    }
  };

  const handleUpdateMenuSummary = async (menuId, newSummary) => {
    try {
      await updateMenuSummary(menuId, newSummary);
      loadVenueItems();
    } catch (error) {
      console.error(`Error updating menu summary: `, error);
      setAlertMessage('Failed to update menu summary.');
    }
  };

  const handleUpdateMenuType = async (menuId, newType) => {
    try {
      await updateMenuType(menuId, newType);
      loadVenueItems();
    } catch (error) {
      console.error(`Error updating menu type: `, error);
      setAlertMessage('Failed to update menu type.');
    }
  };

  const handleDeleteMenuItem = async (menu) => {
    try {
      await deleteMenuItem(menu.id, menu.imageURL);
      loadVenueItems();
    } catch (error) {
      console.error(`Error deleting menu: `, error);
      setAlertMessage('Failed to delete menu.');
    }
  };

  const handleOpenUploadModal = (venueId, venueName) => {
    console.log('Opening upload modal for venueId:', venueId); // Log venueId
    setUploadVenueId(venueId);
    setUploadVenueName(venueName);
    setIsUploadModalOpen(true);
  };

  const handleCloseUploadModal = () => {
    setIsUploadModalOpen(false);
    loadVenueItems();
  };

  const handleInputChange = (e, id, field) => {
    const value = e.target.value;
    setVenueUpdates((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        [field]: value,
      },
    }));
  };

  const handleRefresh = () => {
    loadVenueItems();
  };

  const handleCloseAlert = () => {
    setAlertMessage('');
  };

  const scrollLeft = (venueId) => {
    if (menuListRefs.current[venueId]) {
      menuListRefs.current[venueId].scrollBy({ left: -250, behavior: 'smooth' });
    }
  };

  const scrollRight = (venueId) => {
    if (menuListRefs.current[venueId]) {
      menuListRefs.current[venueId].scrollBy({ left: 250, behavior: 'smooth' });
    }
  };

  if (!venueItems.length) return <p>No venues available.</p>;

  return (
    <div className="admin-venue-list">
      {/* Link to Menu Portal */}
      <div className="menu-portal-link">
        <Link to="/menu-portal" className="menu-portal-button">
          Access Menu Portal
        </Link>
      </div>
      {venueItems.map(
        ({
          id,
          venueid,
          imageDownloadUrl,
          venuename,
          promo,
          address,
          description,
          cuisine,
          payment,
          price,
          telephone,
          website,
        }) => (
          <div key={id} className="admin-venue-item">
            <img
              src={imageDownloadUrl}
              alt={venuename}
              className="admin-venue-image"
              onClick={() => handleImageClick(description)}
            />
            <div className="admin-button-container">
              <button
                className="admin-upload-button"
                onClick={() => handleOpenUploadModal(venueid, venuename)}
              >
                Upload Menu
              </button>
              <button className="admin-refresh-button" onClick={handleRefresh}>
                Refresh Page
              </button>
            </div>
            <div className="admin-venue-text">
              <div className="admin-venue-name">{venuename}</div>
              <div className="admin-venue-info">
                <p>
                  <strong>Promo:</strong> {promo}
                </p>
                <p>
                  <strong>Address:</strong> {address}
                </p>
                <p>
                  <strong>Description:</strong> {description}
                </p>
                <p>
                  <strong>Cuisine:</strong> {cuisine}
                </p>
                <p>
                  <strong>Payment:</strong> {payment}
                </p>
                <p>
                  <strong>Price Range:</strong> {price}
                </p>
                <p>
                  <strong>Telephone:</strong> {telephone}
                </p>
                <p>
                  <strong>Website:</strong>{' '}
                  <a href={website} target="_blank" rel="noopener noreferrer">
                    {website}
                  </a>
                </p>
              </div>
              <div className="admin-promo-update">
                <input
                  type="text"
                  placeholder="Update Promo"
                  value={venueUpdates[id]?.promo || promo}
                  onChange={(e) => handleInputChange(e, id, 'promo')}
                  maxLength="25"
                />
                <button
                  onClick={() => handleUpdateField(id, 'promo', venueUpdates[id]?.promo || promo)}
                >
                  Save Promo
                </button>
                <input
                  type="text"
                  placeholder="Update Cuisine"
                  value={venueUpdates[id]?.cuisine || cuisine}
                  onChange={(e) => handleInputChange(e, id, 'cuisine')}
                  maxLength="25"
                />
                <button
                  onClick={() =>
                    handleUpdateField(id, 'cuisine', venueUpdates[id]?.cuisine || cuisine)
                  }
                >
                  Save Cuisine
                </button>
                <input
                  type="text"
                  placeholder="Update Payment"
                  value={venueUpdates[id]?.payment || payment}
                  onChange={(e) => handleInputChange(e, id, 'payment')}
                  maxLength="25"
                />
                <button
                  onClick={() =>
                    handleUpdateField(id, 'payment', venueUpdates[id]?.payment || payment)
                  }
                >
                  Save Payment
                </button>
                <select
                  value={venueUpdates[id]?.price || price}
                  onChange={(e) => handleInputChange(e, id, 'price')}
                >
                  <option value="">Select Price Range</option>
                  <option value="£">£</option>
                  <option value="££">££</option>
                  <option value="£££">£££</option>
                  <option value="££££">££££</option>
                  <option value="£££££">£££££</option>
                </select>
                <button
                  onClick={() => handleUpdateField(id, 'price', venueUpdates[id]?.price || price)}
                >
                  Save Price Range
                </button>
              </div>
              <div className="admin-menu-list-wrapper">
                <div
                  className="admin-menu-list"
                  ref={(el) => (menuListRefs.current[venueid] = el)}
                >
                  {menuItems[venueid] &&
                    menuItems[venueid].map((menu) => (
                      <div key={menu.id} className="admin-menu-item">
                        <div className="admin-menu-text">
                          <p>
                            <strong>Menu Type:</strong>
                            <select
                              value={venueUpdates[menu.id]?.menuType || menu.menuType}
                              onChange={(e) => handleInputChange(e, menu.id, 'menuType')}
                            >
                              <option value="Standard">Standard</option>
                              <option value="Specials">Specials</option>
                              <option value="Sunday">Sunday</option>
                              <option value="Drinks">Drinks</option>
                              <option value="Events">Events</option>
                            </select>
                          </p>
                          <p>
                            <strong>Summary:</strong> {menu.output || 'N/A'}
                          </p>
                          <textarea
                            rows="5"
                            value={venueUpdates[menu.id]?.output || menu.output}
                            onChange={(e) =>
                              setVenueUpdates((prev) => ({
                                ...prev,
                                [menu.id]: { ...prev[menu.id], output: e.target.value },
                              }))
                            }
                          />
                          <div className="admin-button-container center">
                            <button
                              className="admin-update-button"
                              onClick={() =>
                                handleUpdateMenuSummary(
                                  menu.id,
                                  venueUpdates[menu.id]?.output || menu.output
                                )
                              }
                            >
                              Save Summary
                            </button>
                            <button
                              className="admin-update-button"
                              onClick={() =>
                                handleUpdateMenuType(
                                  menu.id,
                                  venueUpdates[menu.id]?.menuType || menu.menuType
                                )
                              }
                            >
                              Save Menu Type
                            </button>
                          </div>
                          <div className="admin-center">
                            <img
                              src={menu.publicUrl}
                              alt={menu.title}
                              className="admin-menu-image"
                            />
                          </div>
                          <div className="admin-center">
                            <button
                              className="admin-delete-button"
                              onClick={() => handleDeleteMenuItem(menu)}
                            >
                              Delete Menu
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        )
      )}
      <Modal show={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <p>{selectedVenue}</p>
      </Modal>
      <UploadMenuModal
        show={isUploadModalOpen}
        onClose={handleCloseUploadModal}
        venueId={uploadVenueId}
        venueName={uploadVenueName}
        loadVenueItems={loadVenueItems}
      />
      {alertMessage && <CustomAlert message={alertMessage} onClose={handleCloseAlert} />}
    </div>
  );
};

export default VenueList;
